import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Fail early`}</h1>
    <p>{`Instead of accepting bad input and handling it, you should fail and throw
an exception to force the fault up to the surface. If the bad input is
accepted, the error might not be found for a long time.`}</p>
    <br />
    <p>{`The function below is trying to be a good guy and handle bad input. You might
think this is robust, but it is not a good strategy as you will "swollow"
errors from the higher ups.`}</p>
    <br />
    <deckgo-highlight-code {...{
      "language": "javascript"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`export function isDateInTheFuture(timeStamp) {
  if (timeStamp != null) {
    return timeStamp > Date.now()
  } else {
    return false
  }
}`}</code>{`
        `}</deckgo-highlight-code>
    <br />
Instead you should be strict and angry and accept no crap. Let the caller know they are misbehaving by throwing an exception.
    <deckgo-highlight-code {...{
      "language": "javascript"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`export function isDateInTheFuture(timeStamp) {
  assert(isNumber(timeStamp), "Invalid timeStamp")
  return timeStamp > Date.now()
}`}</code>{`
        `}</deckgo-highlight-code>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      